/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #69838F;
$color2:              #A3A57F;
$color3:              $color1; // link, light blue
$color4:              $color2; // link hover, purple

// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Mukta", Helvetica, Arial, sans-serif;
$secondaryFontFamily: "Bebas Neue", Helvetica, Arial, sans-serif;

$headingsFontFamily:  $baseFontFamily;
$headingsColor:       #000;

$hrColor:             #DDD;
